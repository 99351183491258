import * as React from "react";
import { withRouter } from "react-router";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import HomeIcon from "@mui/icons-material/Home";

const Breadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const pathnames = pathname.split("/").filter((x) => x);

  return (
    <MUIBreadcrumbs
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        padding: "7px",
        paddingBottom: "20px",
      }}
      aria-label="breadcrumb"
    >
      {pathnames.length > 0 ? (
        <Link onClick={() => history.push("/")}>
          <span>
            <HomeIcon fontSize="small" color="blue" />
          </span>
          Home
        </Link>
      ) : (
        ""
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const islast = index === pathnames.length - 1;
        return islast ? (
          <Typography key={name}>{name}</Typography>
        ) : (
          <Link key={name} onClick={() => history.push(routeTo)}>
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
