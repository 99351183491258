import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { withRouter, NavLink } from "react-router-dom";
import CustomButton from "../Button/Button";
import { Telegram } from "@mui/icons-material";

import "./Header.css";

const Header = (props) => {
  const pathName = props?.location?.pathname;
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expand="lg" sticky="top" expanded={expanded} className="header">
      <div className="appointment-btn" style={{ color: "white" }}>
        <CustomButton text={"Book Appointment"} icon={<Telegram />} />
      </div>
      <Navbar.Toggle
        onClick={() =>
          setTimeout(() => {
            setExpanded(expanded ? false : "expanded");
          }, 150)
        }
      />

      <Navbar.Collapse>
        <Nav>
          {/* Home Link  */}
          <Nav.Link
            as={NavLink}
            to="/"
            className={pathName === "/" ? "header_link_active" : "header_link"}
            onClick={() => setExpanded(false)}
          >
            Home
          </Nav.Link>

          {/* About us Link  */}
          <Nav.Link
            as={NavLink}
            to="/about-us"
            className={
              pathName === "/about-us" ? "header_link_active" : "header_link"
            }
            onClick={() => setExpanded(false)}
          >
            About us
          </Nav.Link>

          {/* Studies Link  */}
          <Nav.Link
            as={NavLink}
            to="/studies"
            className={
              pathName === "/studies" ? "header_link_active" : "header_link"
            }
            onClick={() => setExpanded(false)}
          >
            Studies
          </Nav.Link>

          {/* Contact Us Link  */}
          <Nav.Link
            as={NavLink}
            to="/contact-us"
            className={
              pathName === "/contact-us" ? "header_link_active" : "header_link"
            }
            onClick={() => setExpanded(false)}
          >
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>

      <div className="header-right">
        <CustomButton text={"Book Appointment"} icon={<Telegram />} />
      </div>
    </Navbar>
  );
};

export default withRouter(Header);
