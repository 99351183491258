import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import { HorizontalRule } from "@mui/icons-material";
import { Timeline } from "@material-ui/lab";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {
  AccountBalance,
  BusinessCenter,
  Language,
  School,
} from "@mui/icons-material/";

import "./ClientSection.css";

const ClientSection = () => {
  return (
    <Container>
      <Grid className="clientSection">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="service-section-header">
              <h2>Our Key Clients</h2>
            </Box>
            <span className="hr">
              <HorizontalRule />
            </span>
          </Grid>
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <School />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                Education and Centers of Excellence
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Language />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                Non-Government Organization (NGOs)
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <AccountBalance />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Banking and Financial Centers</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <BusinessCenter />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>Small and Micro Enterprises</TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientSection;
