import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import image1 from "../../assets/images/carousel-1.jpg";
import image2 from "../../assets/images/carousel-2.jpg";
import image3 from "../../assets/images/carousel-3.jpg";
import CustomButton from "../Button/Button";
import { Bounce, Slide } from "react-reveal";
import "./CarouselContainer.css";

const CarouselContainer = () => {
  return (
    <div>
      <Carousel variant="light">
        <Carousel.Item interval={3000}>
          <img className="d-block w-100" src={image1} alt="First slide" />
          <Carousel.Caption>
            <Slide left>
              <h5>Why you need a good website for your business?</h5>
            </Slide>
            <Slide right>
              <p>
                A good website extends marketing to every aspect of digital
                marketing strategy.As the backbone of your online presence,
                every type of communication, piece of content, or advertisement
                that you put online will drive the consumer back to your
                website....
              </p>
            </Slide>
            <Bounce>
              <Bounce>
                <Link className="custom-btn" to="">
                  <CustomButton text="GET FREE CONSULTATION" />
                </Link>
              </Bounce>
            </Bounce>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100" src={image2} alt="Second slide" />
          <Carousel.Caption>
            <Slide left>
              <h5>We deliver to perfection</h5>
            </Slide>
            <Slide right>
              <p>For all your IT needs...</p>
            </Slide>
            <Bounce>
              <Link className="custom-btn" to="">
                <CustomButton text="GET FREE CONSULTATION" />
              </Link>
            </Bounce>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100" src={image3} alt="Third slide" />
          <Carousel.Caption>
            <Slide left>
              <h5>We are at your service</h5>
            </Slide>
            <Slide right>
              <p>For all your IT needs</p>
            </Slide>
            <Bounce>
              <Link className="custom-btn" to="">
                <CustomButton text="GET FREE CONSULTATION" />
              </Link>
            </Bounce>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselContainer;
