import React from "react";
import { Container, Grid, Box, Link, Tooltip } from "@material-ui/core";
import fredouData from "../../utils/fredouData";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  MdLocationOn,
  MdPhone,
  MdMail,
  MdKeyboardArrowRight,
} from "react-icons/md";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <Box
          px={{ xs: 3, sm: 10, md: 9 }}
          py={{ xs: 5, sm: 10, md: 10 }}
          className="footer-box"
        >
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box borderBottom={1} marginBottom={2}>
                  About Us
                </Box>
                <Box>
                  <p>{fredouData.aboutus}</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box borderBottom={1} marginBottom={2}>
                  Our Services
                </Box>
                {Object.keys(fredouData.Services).map((key, uniquekey) => (
                  <Box key={uniquekey}>
                    <Link
                      to={fredouData.Services[key].link}
                      style={{ color: "white", cursor: "pointer" }}
                    >
                      <span className="useful-links">
                        <MdKeyboardArrowRight className="material-design-icon" />
                        {fredouData.Services[key].text}
                      </span>
                    </Link>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box borderBottom={1} marginBottom={2}>
                  Useful Pages
                </Box>
                {Object.keys(fredouData.quicklinks).map((key, uniquekey) => (
                  <Box key={uniquekey}>
                    <Nav.Link
                      as={NavLink}
                      to={fredouData.quicklinks[key].link}
                      style={{ color: "white" }}
                    >
                      <span className="useful-links">
                        <MdKeyboardArrowRight className="material-design-icon" />
                        {fredouData.quicklinks[key].text}
                      </span>
                    </Nav.Link>
                  </Box>
                ))}
              </Grid>

              <Grid item xs={12} sm={6} md={12} lg={3}>
                <Box borderBottom={1} marginBottom={2}>
                  Get In Touch
                </Box>
                <Box>
                  <p>
                    <MdLocationOn className="material-design-icon" />
                    {fredouData.place}
                  </p>
                </Box>
                <Box>
                  <p>
                    <MdPhone className="material-design-icon" />
                    <a className="contact" href={fredouData.tel}>
                      {fredouData.phone}
                    </a>
                  </p>
                </Box>

                <Box>
                  <p>
                    <MdMail className="material-design-icon" />
                    <a className="contact" href={fredouData.mailto}>
                      {fredouData.email}
                    </a>
                  </p>
                </Box>
                <Box>
                  <Grid className="social">
                    {Object.keys(fredouData.socials).map((key, uniquekey) => (
                      <Tooltip
                        title={fredouData.socials[key].text}
                        placement="top"
                        key={uniquekey}
                      >
                        <a
                          href={fredouData.socials[key].link}
                          rel="noreferrer"
                          data-toggle="tooltip"
                          data-content={fredouData.socials[key].text}
                        >
                          <span className="social-svg">
                            {fredouData.socials[key].icon}
                          </span>
                        </a>
                      </Tooltip>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box
              textAlign="center"
              pt={{ xs: 5, sm: 10 }}
              pb={{ xs: 5, sm: 0 }}
            >
              Designed By{" "}
              <span style={{ color: "pink" }}>Fredouweb Technologies</span>{" "}
              &copy; {new Date().getFullYear()}
            </Box>
          </Container>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
