import React, { useState } from "react";
import { HorizontalRule } from "@mui/icons-material";
import {
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardFooter,
  CardText,
} from "reactstrap";
import {
  Container,
  Grid,
  Box,
  Divider,
  Tabs,
  Tab,
  Typography,
  Grow,
} from "@material-ui/core";
import portfolioData from "../../utils/portfolioData";

import "./GallerySection.css";

const GallerySection = () => {
  const [tabValue, setTabValue] = useState("All");

  return (
    <Container>
      <span className="divider">
        <Divider />
      </span>
      <Grid className="gallery">
        <Grid className="">
          <Box className="section-header">
            <h2>Our Studies</h2>
          </Box>
          <span className="hr">
            <HorizontalRule />
          </span>
        </Grid>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12} className="tabs">
        <Tabs
          value={tabValue}
          indicatorColor="white"
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          <Tab
            label="All"
            value="All"
            className={
              tabValue === "All"
                ? "customTabs_item active-tab"
                : "customTabs_item"
            }
          ></Tab>

          {/* mapping through the project array using `set` in javascript */}
          {[...new Set(portfolioData.projects.map((item) => item.tags))].map(
            (tag) => (
              <Tab
                label={tag}
                value={tag}
                className={
                  tabValue === tag
                    ? "customTabs_item active-tab"
                    : "customTabs_item"
                }
              ></Tab>
            )
          )}
        </Tabs>
      </Grid>

      {/* Projects */}
      <Grid item xs={12} style={{ padding: "20px 17px " }}>
        <Grid container spacing={2}>
          {portfolioData.projects.map((project) => (
            <React.Fragment>
              {tabValue === project.tags || tabValue === "All" ? (
                <Grid
                  item
                  className="portfolio-wrap"
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                >
                  <Grow in timeout={1000}>
                    <Card inverse>
                      <CardImg
                        alt="Card image cap"
                        src={project.image}
                        width="100%"
                      />
                      <CardImgOverlay>
                        <Grid className="caption-wrap">
                          <CardTitle tag="h4" className="overlay-color">
                            {project.title}
                          </CardTitle>
                          <CardText>
                            <Typography className="desc-color">
                              {project.desc}
                            </Typography>
                          </CardText>
                          <CardText className="cardText-date">
                            <small>{project.date}</small>
                          </CardText>
                        </Grid>
                      </CardImgOverlay>
                      <CardFooter className="Cardfooter-color">
                        {project.desc}
                      </CardFooter>
                    </Card>
                  </Grow>
                </Grid>
              ) : null}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default GallerySection;
