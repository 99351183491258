import React from "react";
import { Container, Grid, Box, Link, Divider } from "@material-ui/core";
import aboutimage from "../../assets/images/about.jpg";
import CustomButton from "../Button/Button";
import fredouData from "../../utils/fredouData";
import { HorizontalRule } from "@mui/icons-material";
import image from "../../assets/images/quote.jpg";

import "./AboutSection.css";

function AboutSection() {
  return (
    <div>
      <span className="divider">
        <Divider />
      </span>
      <Box className="about-section">
        <Container>
          <Grid container>
            <Grid item md={6} lg={5}>
              <Box className="about-img">
                <img src={aboutimage} alt="about-us" />
              </Box>
            </Grid>
            <Grid item md={6} lg={7}>
              <Box className="about-section-header">
                <h2>Learn About Us</h2>
              </Box>
              <span className="hr">
                <HorizontalRule />
              </span>

              <Box
                className="boxshadow"
                style={{ padding: "20px", marginLeft: "10px" }}
              >
                <Box className="about-text">
                  <p>{fredouData.aboutus}.Try us today...</p>
                  <h3>Our services include the following:-</h3>
                  <ol>
                    {data.map((list, i) => {
                      const { service } = list;
                      return <li key={i}>{service}</li>;
                    })}
                  </ol>
                </Box>
                <Box className="about-section-btn ">
                  <CustomButton text="See More" />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="keyClients ">
            <Link to="">
              <img src={image} alt="Our Service Quotation" />
            </Link>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

const data = [
  { service: "Web Design and Development(HTML, CSS, PHP, JS, WordPress)" },
  { service: "Computer Maintenance and Repair" },
  { service: "Financial Applications Consultancy in QuickBooks, Tally & Sage" },

  { service: "Graphic Animation(3D Max, MAYA, Toom Boom)" },
  { service: "Advanced Graphic Design(AutoCAD, Arch CAD, Image Rendering)" },
  {
    service:
      "Music Video Production(Adobe Premier Pro, Lightworks, Adobe After Effects)",
  },
  { service: "Computer Programming(Visual Basic, C++,Java Script)" },
  {
    service:
      "Graphic Design Services(Photoshop, Illustrator, Corel Draw, Lightroom)",
  },
  { service: "Search Engine Optimization" },
  { service: "CCTV and Access Control Installation" },
  { service: "Electrical Installation" },
  { service: "Mobile Clinic." },
];
export default AboutSection;
