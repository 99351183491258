import React from "react";
import CarouselContainer from "../../components/CarouselContainer/CarouselContainer";
import TopFeature from "../../components/TopFeature/TopFeature";
import AboutSection from "../../components/AboutSection/AboutSection";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import TeamSection from "../../components/TeamSection/TeamSection";
import FAQsAccordion from "../../components/FAQsAccordion/FAQsAccordion";
import Testimonial from "../../components/Testimonial/Testimonial";

const Home = () => {
  return (
    <div>
      <CarouselContainer />
      <TopFeature />
      <AboutSection />
      <ServiceSection />
      <TeamSection />
      <FAQsAccordion />
      <Testimonial />
    </div>
  );
};

export default Home;
