import React from "react";
import AboutSection2 from "../../components/AboutSection/AboutSection2";
import ClientSection from "../../components/ClientSection/ClientSection";
import TimelineSection from "../../components/TimelineSection/TimelineSection";
import TeamSection from "../../components/TeamSection/TeamSection";
const About = () => {
  return (
    <div>
      <AboutSection2 />
      <ClientSection />
      <TimelineSection />
      <TeamSection />
    </div>
  );
};

export default About;
