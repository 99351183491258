import React from "react";
import "./TopFeature.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PersonIcon from "@mui/icons-material/Person";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

function TopFeature() {
  return (
    <div className="feature-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <CheckCircleOutlineIcon className="material-icons" />
              <h3>Legal</h3>
              <p>Government Approved</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <PersonIcon className="material-icons" />
              <h3>ICT Support</h3>
              <p>Specialists</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <ThumbUpIcon className="material-icons" />
              <h3>Rating</h3>
              <p>&#9733;&#9733;&#9733;&#9734;91% Rate</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="feature-item">
              <ContactSupportIcon className="material-icons" />
              <h3>Support</h3>
              <p>Free Consultation Services</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFeature;
