import React from "react";
import { Container, Grid } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// components
import TopBar from "./components/Header/TopBar";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Breadcrumbs from "./components/Breadcrumbs";

// pages
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Studies from "./pages/Studies/Studies";
import Contact from "./pages/Contact/Contact";

// CSS file
import "./App.css";

function App() {
  return (
    <Container className="boxshadow">
      <Grid item xs>
        <Router>
          <ScrollToTop />
          <TopBar />
          <Header />

          <Breadcrumbs />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about-us">
              <About />
            </Route>
            <Route path="/studies">
              <Studies />
            </Route>
            <Route path="/contact-us">
              <Contact />
            </Route>
          </Switch>

          <Footer />
        </Router>
      </Grid>
    </Container>
  );
}

export default App;
