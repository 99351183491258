// Import svg file
import { ReactComponent as Twitter } from "../assets/social/Twitter.svg";
import { ReactComponent as Facebook } from "../assets/social/Facebook.svg";
import { ReactComponent as LinkedIn } from "../assets/social/LinkedIn.svg";
import { ReactComponent as Instagram } from "../assets/social/Instagram.svg";
import { ReactComponent as Github } from "../assets/social/Github.svg";

import image1 from "../assets/images/webdevelopment.jpg";
import image2 from "../assets/images/graphicdesign.jpg";
import image3 from "../assets/images/softwaredevelopment.jpg";
import image4 from "../assets/images/itconsultancy.jpg";
import image5 from "../assets/images/computermaintenance.jpg";
import image6 from "../assets/images/mobilerepair.jpg";
import image7 from "../assets/images/team-1.jpg";
import image8 from "../assets/images/team-2.jpg";
import image9 from "../assets/images/team-3.jpg";
import image10 from "../assets/images/team-4.jpg";

const fredouData = {
  title: "Fredouweb Technologies",
  aboutus:
    "Fredouweb Technologies is a forum that provides Web Design/Development & ICT related Services. We are formed with an aim of assisting organizations and Institutions in modern technology with a close relationship and deep understanding",
  time: "8:00am - 8:00pm",
  timeTag: "Opening Hour Mon - Sat",
  phone: "(+254) 728 496 513",
  tel: "tel:+254728496513",
  phoneTag: "Call Us For Inquiries",
  place: "Komarocks, Nairobi, KENYA",
  email: "info@fredouwebtech.com",
  mailto: "mailto:info@fredouwebtech.com",

  //   Start Social Icons

  socials: {
    Twitter: {
      link: "https://twitter.com/fredouwebtech",
      text: "Twitter",
      icon: <Twitter className="social-svg" />,
    },
    Facebook: {
      link: "https://web.facebook.com/fredouwebtechnologies",
      text: "Facebook",
      icon: <Facebook className="social-svg" />,
    },
    LinkedIn: {
      link: "https://www.linkedin.com/in/fredouweb-technologies-a7721b209/",
      text: "LinkedIn",
      icon: <LinkedIn className="social-svg" />,
    },
    Instagram: {
      link: "https://www.instagram.com/fredouwebtech/",
      text: "Instagram",
      icon: <Instagram className="social-svg" />,
    },
  },
  //   End Social Icons

  // services
  Services: {
    service1: { text: "Web Design & Development", link: "" },
    service2: { text: "Software Development", link: "" },
    service3: { text: "IT Consultancy", link: "" },
    service4: { text: "ICT Related Courses Training", link: "" },
    service5: { text: "Graphic Design", link: "" },
    service6: { text: "Computer Maintenance", link: "" },
    service7: { text: "Mobile Clinic", link: "" },
  },

  // End Service

  // Start QuickLinks
  // services
  quicklinks: {
    link1: { text: "Home", link: "/" },
    link2: { text: "About Us", link: "/about-us" },
    link4: { text: "Studies", link: "/studies" },
    link6: { text: "Contact Us", link: "/contact-us" },
  },
  // Our services
  ServiceSection: {
    WebDesignDevelopment: {
      image: image1,
      alt: "Web Design & Development",
      typography: "Web Design & Development",
      content:
        "Web design refers to the design of websites that are displayed on the internet. It usually refers to the user experience aspects of website development rather than software development. ... A web designer works on the appearance, layout, and, in some cases, content of a website.",
      text: "Learn More",
      link: "",
    },
    GraphicDesign: {
      image: image2,
      alt: "Graphic Design",
      typography: "Graphic Design",
      content:
        "Graphic design is a craft where professionals create visual content to communicate messages. By applying visual hierarchy and page layout techniques, designers use typography and pictures to meet users' specific needs and focus on the logic of displaying elements in interactive designs, to optimize the user experience.",
      text: "Learn More",
      link: "",
    },
    SoftwareDevelopment: {
      image: image3,
      alt: "Software Development",
      typography: "Software Development",
      content:
        "Software development is the process of conceiving, specifying, designing, programming, documenting, testing, and bug fixing involved in creating and maintaining applications, frameworks, or other software components.",
      text: "Learn More",
      link: "",
    },
    ITConsultancy: {
      image: image4,
      alt: "IT Consultancy",
      typography: "IT Consultancy",
      content:
        "Our role here is to work in partnership with clients, advising them how to use information technology in order to meet their business objectives or overcome problems. You'll work to improve the structure and efficiency of IT systems in various organisations.",
      text: "Learn More",
      link: "",
    },
    ComputerMaintenance: {
      image: image5,
      alt: "Computer Maintenance",
      typography: "Computer Maintenance",
      content:
        "In Computer maintenance, we the practice of keeping computers in a good state of repair. A computer containing accumulated dust and debris may not run properly. Dust and debris will accumulate as a result of air cooling. Any filters used to mitigate this need regular service and changes.",
      text: "Learn More",
      link: "",
    },
    MobilePhoneClinic: {
      image: image6,
      alt: "Mobile Phone Clinic",
      typography: "Mobile Phone Clinic",
      content:
        "Our able Mobile phone repair technicians run tests to assess the mobile phones' functionality, install and update phone software, troubleshoot wiring problems, and replace damaged parts and components such as batteries, LCD screens, keypads, buttons.",
      text: "Learn More",
      link: "",
    },
  },

  // START OUR TEAM
  Team: {
    FredMarinda: {
      image: image7,
      name: "Fred Marinda",
      role: "CEO Fredouweb Technologies",
      Facebook_link: "",
      Facebook_icon: <Facebook className="social-svg" />,
      Twitter_link: "",
      Twitter_icon: <Twitter className="social-svg" />,
      LinkedIn_link: "",
      LinkedIn_icon: <LinkedIn className="social-svg" />,
      Instagram_link: "",
      Instagram_icon: <Instagram className="social-svg" />,
    },
    DouglasObara: {
      image: image8,
      name: "Douglas Obara",
      role: "Web Design Specialist",
      Facebook_link: "https://web.facebook.com/douglas.omwando.92/",
      Facebook_icon: <Facebook className="social-svg" />,
      Twitter_link: "https://twitter.com/Douglas__Obara",
      Twitter_icon: <Twitter className="social-svg" />,
      LinkedIn_link: "https://www.linkedin.com/in/douglas-obara/",
      LinkedIn_icon: <LinkedIn className="social-svg" />,
      Instagram_link: "https://github.com/douglas254",
      Instagram_icon: <Github className="social-svg" />,
    },

    JustinePrince: {
      image: image9,
      name: "Justine Prince",
      role: "IT Support Technician",
      Facebook_link: "",
      Facebook_icon: <Facebook className="social-svg" />,
      Twitter_link: "",
      Twitter_icon: <Twitter className="social-svg" />,
      LinkedIn_link: "",
      LinkedIn_icon: <LinkedIn className="social-svg" />,
      Instagram_link: "",
      Instagram_icon: <Instagram className="social-svg" />,
    },
    GraceyLisa: {
      image: image10,
      name: "Jessicah Ojwang'",
      role: "IT Consultant",
      Facebook_link: "",
      Facebook_icon: <Facebook className="social-svg" />,
      Twitter_link: "",
      Twitter_icon: <Twitter className="social-svg" />,
      LinkedIn_link: "",
      LinkedIn_icon: <LinkedIn className="social-svg" />,
      Instagram_link: "",
      Instagram_icon: <Instagram className="social-svg" />,
    },
  },

  // END OUR TEAM
};

export default fredouData;
