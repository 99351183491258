import React from "react";
import { Grid, Box, Container } from "@material-ui/core";
import { HorizontalRule } from "@mui/icons-material";
import fredouData from "../../utils/fredouData";
import "./TeamSection.css";

function TeamSection() {
  return (
    <Container>
      <Grid className="team">
        <Grid className="card-boxshadow">
          <Box className="section-header">
            <h2>Meet Our ICT Specialists</h2>
          </Box>
          <span className="hr">
            <HorizontalRule />
          </span>
        </Grid>
        <Grid container spacing={2} className="shadow">
          {Object.keys(fredouData.Team).map((key, uniquekey) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={uniquekey}>
              <Box className="row">
                <Box className="team-item">
                  <Box className="team-img">
                    <img
                      src={fredouData.Team[key].image}
                      alt={fredouData.Team[key].name}
                    />
                  </Box>
                  <Box className="team-text">
                    <h2>{fredouData.Team[key].name}</h2>
                    <p>{fredouData.Team[key].role}</p>
                    <Box className="team-social">
                      <a
                        className="social-tw"
                        href={fredouData.Team[key].Twitter_link}
                      >
                        {fredouData.Team[key].Twitter_icon}
                      </a>
                      <a
                        className="social-fb"
                        href={fredouData.Team[key].Facebook_link}
                      >
                        {fredouData.Team[key].Facebook_icon}
                      </a>
                      <a
                        className="social-li"
                        href={fredouData.Team[key].LinkedIn_link}
                      >
                        {fredouData.Team[key].LinkedIn_icon}
                      </a>
                      <a
                        className="social-in"
                        href={fredouData.Team[key].Instagram_link}
                      >
                        {fredouData.Team[key].Instagram_icon}
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default TeamSection;
