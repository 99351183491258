import React from "react";
import { HorizontalRule } from "@mui/icons-material";
import { Container, Grid, Box } from "@material-ui/core";
import "./TimelineSection.css";

const TimelineSection = () => {
  return (
    <Container className="timeline">
      <Grid className="container">
        <Grid item xs={12}>
          <Box className="section-header">
            <h2>What makes our success yearly</h2>
          </Box>
          <span className="hr">
            <HorizontalRule />
          </span>
        </Grid>
        <Grid>
          <Grid className="timeline-container left">
            <Grid className="timeline-content">
              <h2>
                <span>Tip 1</span>Increasing of our company press coverage and
                market recognition
              </h2>
              <p>
                Fredouweb Technologies discovered new marketing strategies to
                build and increase brand awareness among, serious case Studies
                and research among others ... Brand awareness is the way in
                which consumers recognize and remember your business.
              </p>
            </Grid>
          </Grid>
          <Grid className="timeline-container right">
            <Grid className="timeline-content">
              <h2>
                <span>Tip 2</span>Increasing the loyalty or satisfaction of
                existing customers
              </h2>
              <p>
                Customer loyalty is a direct result of what we say is integrity
                displayed by the company. Our customers always appreciate our
                honesty, loyalty and integrity
              </p>
            </Grid>
          </Grid>
          <Grid className="timeline-container left">
            <Grid className="timeline-content">
              <h2>
                <span>Tip 3</span>Launching new services, projects or
                initiativest
              </h2>
              <p>
                A successful service launch plan is only as good as the work
                your marketing, If only you try to build anticipation among your
                audience by promoting the product prior to launch, the awareness
                will be more prevalent
              </p>
            </Grid>
          </Grid>
          <Grid className="timeline-container right">
            <Grid className="timeline-content">
              <h2>
                <span>Tip 4</span>Meeting deadlines in customer service
              </h2>
              <p>
                Just by Knowing your deadline timeline...Prioritise the tasks,
                have a good work plan, Allow enough time, Understand the
                requirements, Don't be afraid to ask questions. Remove any
                distractions
              </p>
            </Grid>
          </Grid>
          <Grid className="timeline-container left">
            <Grid className="timeline-content">
              <h2>
                <span>Tip 5</span>Implimentation
              </h2>
              <p>
                Create a Customer Service Vision, Assess Customer Needs, Hire
                the Right Employees, Set Goals for Customer Service, Train on
                Service Skills.
              </p>
            </Grid>
          </Grid>
          <Grid className="timeline-container right">
            <Grid className="timeline-content">
              <h2>
                <span>Tip 6</span>Be Accountable
              </h2>
              <p>
                Obligation or willingness to accept responsibility for one's
                actions
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TimelineSection;
