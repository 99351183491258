import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import imagetest from "../../assets/Testimonial/person.jpg";
import { Carousel } from "react-responsive-carousel";
import { Container, Grid, Box, Divider } from "@material-ui/core";
import { HorizontalRule } from "@mui/icons-material";

import "./Testimonial.css";

const Testimonials = () => {
  return (
    <div
      className="testimonial-shadow"
      style={{ backgroundColor: "", margin: "30px" }}
    >
      <Divider variant="middle" style={{ backgroundColor: "black" }} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="section-header">
              <h2>Testimonials</h2>
            </Box>
            <span className="hr">
              <HorizontalRule />
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "", marginBottom: "30px" }}
          >
            <Carousel
              showArrows={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={6100}
              stopOnHover={false}
              swipeable={false}
            >
              {data.map((index, i) => {
                const { image, name, title, content } = index;
                return (
                  <Box key={i}>
                    <img src={image} alt={name} />
                    <Box className="myCarousel">
                      <h3>{name}</h3>
                      <h4>{title}</h4>
                      <p>{content}</p>
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const data = [
  {
    image: imagetest,
    name: "Client A",
    title: "Company A",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sagittis facilisis nibh at tempus. Morbi vitae dui consequat.",
  },
  {
    image: imagetest,
    name: "Client B",
    title: "Company B",
    content:
      "Nam imperdiet nunc vitae orci accumsan, quis fermentum metus iaculis. Fusce vel scelerisque elit. Morbi auctor purus ut tristique varius.",
  },
  {
    image: imagetest,
    name: "Client C",
    title: "Company C",
    content:
      "Donec tempus mauris at metus dignissim efficitur. Phasellus eu venenatis elit. Praesent auctor diam et iaculis dictum. .",
  },
];
export default Testimonials;
