import * as React from "react";
import CustomButton from "../Button/Button";
import { Grid, Box, Container } from "@material-ui/core";
import fredouData from "../../utils/fredouData";
import { HorizontalRule } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";

import "./ServiceSection.css";

const ServiceSection = () => {
  return (
    <Container>
      <Grid className="service ">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="service-section-header">
              <h2>Our Practical Areas</h2>
            </Box>
            <span className="hr">
              <HorizontalRule />
            </span>
          </Grid>
          {Object.keys(fredouData.ServiceSection).map((key, uniquekey) => (
            <Grid item xs={12} sm={6} md={4} key={uniquekey}>
              <Card className="card-boxshadow">
                <CardActionArea>
                  <div className="overflow">
                    <CardMedia
                      component="img"
                      height="200"
                      image={fredouData.ServiceSection[key].image}
                      alt="web development"
                      className="card-img"
                      title={fredouData.ServiceSection[key].alt}
                    />
                  </div>
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      <span className="center">
                        {fredouData.ServiceSection[key].typography}
                      </span>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <span className="center">
                        {fredouData.ServiceSection[key].content}
                      </span>
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className="center">
                  <CustomButton text={fredouData.ServiceSection[key].text} />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServiceSection;
