import React from "react";
import GallerySection from "../../components/GallerySection/GallerySection";

const Studies = () => {
  return (
    <div>
      <GallerySection />
    </div>
  );
};

export default Studies;
