import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import fredouData from "../../utils/fredouData";
import { Tooltip } from "@material-ui/core";

// Import Images from assets
import logo from "../../assets/images/logo.png";

// Css file
import "./TopBar.css";

const TopBar = (props) => {
  return (
    <>
      <div className="top-bar">
        <div className="logo col-lg-3">
          <Link to="/" data-tip data-for="registerTip">
            <img src={logo} alt="logo" />
          </Link>
          <ReactTooltip
            id="registerTip"
            place="right"
            type="dark"
            effect="solid"
          >
            {fredouData.title}
          </ReactTooltip>
        </div>
        <div className="text">
          <h2>{fredouData.time}</h2>
          <p>{fredouData.timeTag}</p>
        </div>
        <div className="text">
          <h2>{fredouData.phone}</h2>
          <p>{fredouData.phoneTag}</p>
        </div>
        <div className="col-lg-2">
          <div className="social">
            {Object.keys(fredouData.socials).map((key, uniquekey) => (
              <div key={uniquekey}>
                <Tooltip title={fredouData.socials[key].text}>
                  <a
                    href={fredouData.socials[key].link}
                    rel="noreferrer"
                    data-toggle="tooltip"
                    data-content={fredouData.socials[key].text}
                    key={key.id}
                  >
                    <span className="social-svg">
                      {fredouData.socials[key].icon}
                    </span>
                  </a>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(TopBar);
