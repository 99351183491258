import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import aboutimage from "../../assets/images/about.jpg";
import CustomButton from "../Button/Button";
import fredouData from "../../utils/fredouData";

import "./AboutSection.css";
import { HorizontalRule } from "@mui/icons-material";

function AboutSection() {
  return (
    <div>
      <Box className="about-section">
        <Container>
          <Grid container>
            <Grid item md={6} lg={5}>
              <Box className="about-img">
                <img src={aboutimage} alt="about-us" />
              </Box>
            </Grid>
            <Grid item md={6} lg={7}>
              <Box className="about-section-header">
                <h2>Learn About Us</h2>
              </Box>
              <span className="hr">
                <HorizontalRule />
              </span>

              <Box
                className="boxshadow"
                style={{ padding: "20px", marginLeft: "10px" }}
              >
                <Box className="about-text">
                  <p>{fredouData.aboutus}.Try us today...</p>
                </Box>
                <Box className="about-section-btn ">
                  <CustomButton text="See More" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default AboutSection;
