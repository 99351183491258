import React from "react";
import "./ContactSection.css";
import { Container, Grid, Box, Divider } from "@material-ui/core";
import { HorizontalRule } from "@mui/icons-material";
import contactImg from "../../assets/images/contactpic.jpg";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

const ContactSection = () => {
  // emailjs contact form configurations
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lhsq7v5",
        "template_dvdsv9a",
        e.target,
        "user_G3OslamOKwl2u5UX5LQXK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();

    swal({
      title: "Thank you for getting in touch!",
      text: "We appreciate you contacting us Fredouweb Technologies. One of our colleagues will get back in touch with you soon!Have a great day!",
      icon: "success",
      button: "Ok",
    });
  };

  const {
    register,
    formState: { errors },
    trigger,
  } = useForm();

  return (
    <div>
      <span className="divider">
        <Divider />
      </span>
      <Box className="faqs-section">
        <Container>
          <Grid className="gallery">
            <Grid className="">
              <Box className="section-header">
                <h2>Contact Us 🤳</h2>
              </Box>
              <span className="hr">
                <HorizontalRule />
              </span>
            </Grid>
          </Grid>

          <Grid container>
            <Grid className="contact-img" item xs={12} md={6} lg={5}>
              <Box>
                <img
                  src={contactImg}
                  alt="contact "
                  width="100%"
                  height="100%"
                  style={{ marginTop: "10px", border: "1px dotted green" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <Box
                className="boxshadow"
                style={{ padding: "15px", margin: "10px" }}
              >
                <Box>
                  <form className="form" method="POST" onSubmit={sendEmail}>
                    <label>Name </label>
                    <input
                      type="text"
                      placeholder="Full Name"
                      {...register("name", {
                        required: "Name is Required",
                        pattern: {
                          value: /^[A-Za-z]+$/,
                          message: "",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("name");
                      }}
                      name="name"
                      required
                    />
                    {errors.name && (
                      <small className="text-danger">
                        {errors.name.message}
                      </small>
                    )}
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Email is Required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Invalid email address",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("email");
                      }}
                      name="email"
                      required
                    />
                    {errors.email && (
                      <small className="text-danger">
                        {errors.email.message}
                      </small>
                    )}

                    <label>Message</label>
                    <textarea
                      placeholder="Message"
                      {...register("message", {
                        required: "Message is Required",
                        minLength: {
                          value: 10,
                          message: "Minimum Required length is 10",
                        },
                      })}
                      name="message"
                      required
                    ></textarea>

                    {errors.message && (
                      <small className="text-danger">
                        {errors.message.message}
                      </small>
                    )}
                    <button type="submit">Submit</button>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ContactSection;
