import image1 from "../assets/Portfolio/portfolio-1.jpg";
import image2 from "../assets/Portfolio/portfolio-2.jpg";
import image3 from "../assets/Portfolio/portfolio-3.jpg";
import image4 from "../assets/Portfolio/portfolio-4.jpg";
import image5 from "../assets/Portfolio/portfolio-5.jpg";
import image6 from "../assets/Portfolio/portfolio-6.jpg";
import image7 from "../assets/Portfolio/portfolio-7.jpg";
import image8 from "../assets/Portfolio/portfolio-8.jpg";

const portfolioData = {
  projects: [
    {
      tags: "Web Design / Development",
      image: image1,
      title: "Information Technology",
      desc: "Practical ICT Training",
      date: "As at Jan 12 2021",
    },
    {
      tags: "Web Design / Development",
      image: image7,
      title: "Web Design and Development",
      desc: "Web Design",
      date: "As at Jan 12 2021",
    },
    {
      tags: "Web Design / Development",
      image: image3,
      title: "Training Session",
      desc: "ICT Classes",
      date: "As at Jan 12 2021",
    },
    {
      tags: "Graphic Design",
      image: image4,
      title: "Graphic Design",
      desc: "Design & Branding",
      date: "As at Jan 12 2021",
    },
    {
      tags: "Networking",
      image: image5,
      title: "Electrical Installation & Networking",
      desc: "Electrical & Networking",
      date: "As at Jan 12 2021",
    },
    {
      tags: "Networking",
      image: image8,
      title: "Switches & Router Configurations",
      desc: "Network switch",
      date: "As at Jan 12 2021",
    },
    {
      tags: "mobile phone technology",
      image: image6,
      title: "Mobile Clinic",
      desc: "Mobi care work station",
      date: "As at Jan 12 2021",
    },
    {
      tags: "Web Design / Development",
      image: image2,
      title: "Research",
      desc: "Research Centre",
      date: "As at Jan 12 2021",
    },
  ],
};
export default portfolioData;
