import * as React from "react";
import { Container, Grid, Box, Divider } from "@material-ui/core";
import faqsimage from "../../assets/images/faqs.jpg";
import {
  HorizontalRule,
  QuestionAnswer,
  ExpandMore,
} from "@mui/icons-material";
import CustomButton from "../Button/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import "./FAQsAccordion.css";

const FAQsAccordion = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Divider variant="middle" style={{ backgroundColor: "black" }} />
      <Box className="faqs-section">
        <Container>
          <Grid container>
            <Grid item xs={12} md={6} lg={5}>
              <Box className="faqs-img">
                <img src={faqsimage} alt="Frequently Asked Questions(FAQs)" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <Box className="boxshadow">
                <Box className="faqs-section-header" xs={12}>
                  <h2>Frequently Asked Questions(FAQs)?</h2>
                </Box>
                <span className="hr">
                  <HorizontalRule />
                </span>
              </Box>

              <Box
                className="boxshadow"
                style={{ padding: "15px", margin: "10px" }}
              >
                <Box className="faqs-text">
                  {/* iterating through the array */}
                  {data.map((accordion) => {
                    const { id, question, answer, question_no } = accordion;

                    return (
                      <Accordion
                        expanded={expanded === id}
                        onChange={handleChange(id)}
                        key={id}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{
                              width: "80%",
                              flexShrink: 0,
                              color: "black",
                            }}
                          >
                            <span>{question_no}</span>
                            {question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{answer}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
                <Box className="faqs-section-btn ">
                  <CustomButton text="ASK MORE" icon={<QuestionAnswer />} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider variant="middle" style={{ backgroundColor: "black" }} />
    </div>
  );
};

const data = [
  {
    question_no: "1",
    id: "panel1",
    question: "How do you keep your technology skills current?",
    answer:
      "This mainly by setting time aside, finding innovators in your space and follow them, Attending technical conferences and trade shows & Seek and reseach online courses and credentials.",
  },
  {
    question_no: "2",
    id: "panel2",
    question:
      "Explain how an inconversant technology person can be convinced to opt for your services?",
    answer:
      "This plays a big role in Fredouweb Technologies, the ability to communicate with non-technical person quit a hard task. So we normally assess a client's communication skills first the see the best way possible to assist satisfactorily..",
  },
  {
    question_no: "3",
    id: "panel3",
    question:
      "What strengths do you think are most important in a developer (or another relevant IT position)?",
    answer:
      "Positive Attitude, A great developer cares about your product's success, Supreme Communication Skills & Good Time and Task Management",
  },
  {
    question_no: "4",
    id: "panel4",
    question: "How do you handle tight deadlines?",
    answer:
      "Clear your work schedule. Start by clearing your schedule and to-do list of anything that isn't a high priority, Get help from the beginning, Break the project down.",
  },
  {
    question_no: "5",
    id: "panel5",
    question: "What makes you unique?",
    answer:
      "find that it’s easy us to relate to a wide variety of people. For that reason, We really thrive in a team environment. We enjoy discovering each of my teammates skills and strengths and helping use those to determine which tasks they would be best suited for. In my last job, I was in a group that was tasked with completing a complicated task",
  },
];

export default FAQsAccordion;
